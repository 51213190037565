<template>
  <div id="catalog-page">
    <h2 style="font-size: 32px">Catalogs</h2>
    <p style="color: #5e6a6e">
      New catalogs are updated everyday. Feel free to find any catalogs that suit your business.
    </p>
    <v-row dense>
      <v-col cols="12" sm="8" md="6">
        <v-text-field
          placeholder="Search by catalog name"
          autocomplete="off"
          prepend-inner-icon="mdi-magnify pointer"
          hide-details="auto"
          background-color="white"
          v-model.trim="model.searchText"
          @change="getCatalogs"
        />
      </v-col>
    </v-row>
    <div class="d-flex align-center justify-space-between my-2">
      <v-chip-group v-model="tag" active-class="primary" mandatory @change="getCatalogs">
        <v-chip v-for="item in tags" :key="item" :value="item" label>
          {{ item }}
        </v-chip>
      </v-chip-group>
      <v-chip-group v-model="catalogListView" color="primary" active-class="primary" mandatory>
        <v-chip v-for="viewStyle in catalogListViews" :key="viewStyle" :value="viewStyle" label>
          <v-icon v-if="viewStyle === 'grid'" small>mdi-view-grid-outline</v-icon>
          <v-icon v-else small>mdi-format-list-bulleted</v-icon>
        </v-chip>
      </v-chip-group>
    </div>
    <section v-if="catalogListView === 'grid'">
      <div v-if="isLoading" class="catalog-list">
        <v-skeleton-loader v-for="(_, index) in [...Array(12)]" :key="index" type="card" />
      </div>
      <div v-else>
        <ul v-if="catalogList.length" class="catalog-list mb-8">
          <li v-for="catalog in catalogList" :key="catalog.id" class="catalog" :title="catalog.title">
            <div class="catalog-image mb-2" @click="onClickCatalog(catalog)">
              <img :src="formatImage(catalog.images[0])" :alt="catalog.title" />
            </div>
            <h4 class="catalog-name mb-1">{{ catalog.title }}</h4>
            <!-- <div class="catalog-price">
              <label>Price range</label>
              <p class="mb-0">{{ parsePriceRange(catalog.minPrice, catalog.maxPrice) }}</p>
            </div> -->
            <div class="catalog-price mb-2">
              <label>Base cost</label>
              <p class="mb-0 font-weight-medium" style="text-decoration: line-through;">
                {{ formatPrice(catalog.baseCost) }}
              </p>
              <p class="mb-0 error--text font-weight-bold">{{ formatPrice(catalog.baseCost - rankDiscount) }}</p>
            </div>
            <!-- <div class="catalog-price mb-2">
              <label>Profit margin</label>
              <p class="mb-0">{{ catalog.profitMargin }}</p>
            </div> -->
            <v-btn
              class="catalog-btn-import"
              color="#e1e1e1"
              :loading="catalog.isLoadingImport"
              @click="onImportCatalog(catalog)"
              block
              depressed
              >import to store</v-btn
            >
          </li>
        </ul>
        <div v-else class="no-data">
          <span class="mdi mdi-cube-outline"></span>
          <p>There are no catalogs</p>
        </div>
      </div>
    </section>
    <v-data-table
      v-else
      :loading="isLoading"
      :headers="headers"
      :items="catalogList"
      :items-per-page="pagination.limit"
      :page.sync="pagination.page"
      no-data-text="There are no catalogs"
      item-key="_id"
      class="mb-4"
      hide-default-footer
    >
      <template v-slot:[`item.photo`]="{ item }">
        <div class="catalog-table-image my-2" @click="onClickCatalog(item)">
          <img :src="formatImage(item.images[0])" :alt="item.title" />
        </div>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <router-link
          :to="{
            name: 'website.catalog.detail',
            params: {
              id: item._id,
            },
          }"
          class="catalog-table-link"
          >{{ item.title }}</router-link
        >
      </template>
      <template v-slot:[`item.priceRange`]="{ item }">
        <span style="text-wrap: nowrap"> {{ parsePriceRange(item.minPrice, item.maxPrice) }}</span>
      </template>
      <template v-slot:[`item.baseCost`]="{ item }">
        <span style="text-decoration: line-through; font-weight: 500; color: rgb(141 141 141)">{{
          formatPrice(item.baseCost)
        }}</span>
        <span class="error--text ml-1 font-weight-bold">{{ formatPrice(item.baseCost) }}</span>
      </template>
      <template v-slot:[`item.importStores`]="{ item }">
        <v-btn
          :loading="item.isLoadingImport"
          @click="onImportCatalog(item)"
          class="px-2"
          color="primary"
          depressed
          small
          >Import to store</v-btn
        >
      </template>
    </v-data-table>
    <nav
      v-if="!model.searchText && catalogList.length"
      class="d-flex flex-column flex-sm-row align-center align-sm-start justify-sm-space-between"
    >
      <v-select
        :items="valueLimits"
        v-model="pagination.limit"
        style="max-width: 170px"
        suffix="/ page"
        @change="getCatalogs"
        dense
      />
      <v-pagination
        :limitAdd="10"
        :visible="7"
        :length="pagination.length"
        :total-visible="10"
        v-model="pagination.page"
        @input="getCatalogs"
      ></v-pagination>
    </nav>
  </div>
</template>

<script>
import { catalogService } from '@/apis/catalog';
import imageHelpers from '@/helpers/image';
export default {
  data() {
    return {
      catalogs: [],
      catalogList: [],
      selectedList: [],
      valueLimits: [20, 40, 60, 100],
      model: {
        // catalog: null,
        // chooseAllcatalogs: false,
        searchText: null,
      },
      isLoading: true,
      pagination: {
        page: 1,
        length: 1,
        limit: 20,
      },
      catalogListViews: ['grid', 'table'],
      catalogListView: 'grid',
      headers: [
        {
          text: 'Photo',
          value: 'photo',
          width: 40,
          sortable: false,
        },
        {
          text: 'Name',
          value: 'title',
          sortable: false,
        },
        // {
        //   text: 'Price Range',
        //   value: 'priceRange',
        //   align: 'end',
        //   sortable: false,
        // },
        {
          text: 'Base Cost',
          value: 'baseCost',
          align: 'end',
          sortable: false,
          width: 150,
        },
        // {
        //   text: 'Profit Margin',
        //   value: 'profitMargin',
        //   align: 'end',
        //   sortable: false,
        //   width: 150,
        // },
        {
          text: 'Actions',
          value: 'importStores',
          align: 'center',
          sortable: false,
          width: 150,
        },
      ],
      tags: ['All', 'New', 'Trending', 'Best Seller'],
      tag: 'all',
    };
  },
  computed: {
    rankDiscount() {
      return this.$store.state.subscription.currentSubscription?.rankDiscounts?.['1'] || 0;
    },
  },
  methods: {
    async getTags() {
      try {
        this.isLoading = true;
        const { data, status } = await catalogService.getCatalogTags();
        if (status === 200) {
          this.tags = data?.catalogs || [];
          this.tags.unshift('All');
          this.tag = 'all';
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:246 ~ getTags ~ error:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async getCatalogs() {
      try {
        this.isLoading = true;
        const params = {
          page: this.pagination.page,
          limit: this.pagination.limit,
          search: this.model.searchText,
          tag: this.tag.toLowerCase(),
        };
        if (params.tag === 'all') {
          delete params.tag;
        }
        const [catalogs, count] = await Promise.all([
          catalogService.getCatalogs(params),
          catalogService.countCatalogs(),
        ]);
        if (catalogs.status === 200 && count.status === 200) {
          this.catalogList = catalogs.data?.catalogs?.map(item => ({ ...item, isLoadingImport: false })) || [];
          this.pagination.length = Math.ceil(count.data.count / this.pagination.limit);
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:227 ~ getCatalogs ~ error:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async onImportCatalog(catalog) {
      try {
        catalog.isLoadingImport = true;
        const currentStore = localStorage.getItem('store-id');
        const _catalog = {
          ...catalog,
          baseCost: catalog.baseCost - this.rankDiscount,
          variants: catalog.variants.map(item => ({ ...item, baseCost: item.baseCost - this.rankDiscount })),
        };
        const dataPost = {
          products: [{ ..._catalog, stores: [currentStore], catalog: _catalog._id }],
        };
        await catalogService.importProductToStore(dataPost);
      } catch (error) {
        console.log('🚀 ~ file: index.vue:186 ~ onImportProduct ~ error:', error);
      } finally {
        catalog.isLoadingImport = false;
      }
    },
    formatPrice(price) {
      if (!price) return '$0';
      return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    formatImage(image) {
      return image ? imageHelpers.url(image, { width: 320, height: 320 }) : require('@/assets/images/no-image.png');
    },
    parsePriceRange(a, b) {
      const from = this.formatPrice(a);
      const to = this.formatPrice(b);
      return `${from} ~ ${to}`;
    },
    onClickCatalog(catalog) {
      this.$router.push({
        name: 'website.catalog.detail',
        params: {
          id: catalog._id,
        },
      });
    },
  },
  created() {
    this.getTags();
    this.getCatalogs();
  },
};
</script>

<style lang="scss" scoped>
#catalog-page {
  .catalog-list {
    padding-left: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 2rem;
    @media screen and (min-width: 600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (min-width: 960px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    li.catalog {
      position: relative;
      // &.catalog-selected {
      //   span.mdi {
      //     display: block;
      //   }
      // }
      // span.mdi {
      //   position: absolute;
      //   font-size: 24px;
      //   line-height: 24px;
      //   right: -12px;
      //   top: -12px;
      //   z-index: 1;
      //   display: none;
      // }
      .catalog-image {
        width: 100%;
        overflow: hidden;
        border-radius: 8px;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .catalog-name {
        font-weight: 500;
        color: #374151;
        overflow: hidden;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
        white-space: pre-wrap; /* let the text wrap preserving spaces */
        min-height: 42px;
      }
      .catalog-price {
        display: flex;
        align-items: center;
        gap: 6px;
        label {
          color: rgb(85, 85, 85);
          font-size: 13px;
          margin-right: auto;
        }
        p,
        a {
          font-weight: 500;
          font-size: 14px;
          display: block;
          color: rgb(112, 112, 112);
        }
      }
    }
  }
  .no-data {
    text-align: center;
    color: #676767;
    margin-top: 150px;
    span {
      font-size: 48px;
    }
    p {
      font-size: 16px;
    }
  }
}
::v-deep .v-pagination li button {
  box-shadow: none;
  border: solid 1px #dadce0;
  border-radius: 8px;
}
::v-deep .catalog-btn-import {
  height: 38px !important;
  background-color: #e1e1e1;
  border-radius: 8px;
  .v-btn__content {
    text-transform: capitalize;
    color: rgb(17, 24, 39);
    font-weight: 500;
    font-size: 14px;
  }
}
::v-deep .v-input .v-text-field__suffix {
  font-weight: 400 !important;
}
.catalog-table-image {
  width: 100%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff !important;
  &:hover {
    opacity: 0.8;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.catalog-table-link {
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
</style>
